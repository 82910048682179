<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Localidade" : "Cadastrar Localidade" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Localidades</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">Cidade *</span>
              <v-text-field
                v-model="dados.cidade"
                :rules="fieldRules"
                required
                placeholder="Informe a cidade"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Localidade *</span>
              <v-text-field
                v-model="dados.nome"
                :rules="fieldRules"
                required
                placeholder="Informe a localidade"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Estado *</span>
              <v-text-field
                v-model="dados.estado"
                placeholder="Informe o estado"
                class="mt-n1"
                :rules="fieldRules"
                required
                v-mask="'UU'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Sigla *</span>
              <v-text-field
                v-model="dados.sigla"
                placeholder="Informe a sigla"
                class="mt-n1"
                :rules="fieldRules"
                required
                v-mask="'UUUU'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Latitude</span>
              <v-text-field
                v-model="dados.latitude"
                placeholder="Informe a latitude"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Longitude</span>
              <v-text-field
                v-model="dados.longitude"
                placeholder="Informe a longitude"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">IBGE *</span>
              <v-text-field
                v-model="dados.ibge"
                placeholder="IBGE"
                class="mt-n1"
                :rules="fieldRules"
                required
                v-mask-number
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">ANTT</span>
              <v-text-field
                v-model="dados.antt"
                placeholder="ANTT"
                class="mt-n1"
                v-mask-number
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="3">
              <span class="primary--text">SIGMA</span>
              <v-text-field
                v-model="dados.sigma"
                placeholder="SIGMA"
                class="mt-n1"
              ></v-text-field>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <v-spacer />
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        estado: "",
        cidade: "",
        nome: "",
        sigla: "",
        latitude: "",
        longitude: "",
        ibge: 0,
        antt: 0,
        sigma: "",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/localidades/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.dados.nome = this.dados.nome
        ? this.dados.nome.toUpperCase()
        : this.dados.nome;
      this.dados.cidade = this.dados.cidade
        ? this.dados.cidade.toUpperCase()
        : this.dados.cidade;
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/localidades/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push(
                  "/cadastros-operacionais/localidades/gerenciar"
                ); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/localidades/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push(
                  "/cadastros-operacionais/localidades/gerenciar"
                );
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
